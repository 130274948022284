button {
  margin:10px  10px 10px 10px  !important;
}

.MuiDialog-paper{
  min-width: 400px;
}

#orderForm .MuiGrid-justify-xs-flex-end{
  display: none;
}

input[type=string] {
  pointer-events: none;
}

body{
  background-color: #b7b7b721;
}